export default function (color: string, percent: number = 100) {
  // Check if color exist
  if (!color) return ''

  // Strip the leading # if it's there
  color = color.replace(/^\s*#|\s*$/g, '')

  // Convert 3 char codes -> 6, e.g. `E0F` -> `EE00FF`
  if (color.length == 3) {
    color = color.replace(/(.)/g, '$1$1')
  }

  // Split HEX Color
  const hexR = color.substring(0, 2)
  const hexG = color.substring(2, 4)
  const hexB = color.substring(4, 6)

  // HEX to RGB
  let r = parseInt(hexR, 16)
  let g = parseInt(hexG, 16)
  let b = parseInt(hexB, 16)

  if (isNaN(r)) r = 0
  if (isNaN(g)) g = 0
  if (isNaN(b)) b = 0

  // Manipulate
  const newR = Math.min(255, Math.floor(r + (r * percent) / 100))
  const newG = Math.min(255, Math.floor(g + (g * percent) / 100))
  const newB = Math.min(255, Math.floor(b + (b * percent) / 100))

  // RGB to HEX
  const newHexRColor = `${newR.toString(16)}`.padStart(2, '0')
  const newHexGColor = `${newG.toString(16)}`.padStart(2, '0')
  const newHexBColor = `${newB.toString(16)}`.padStart(2, '0')

  return '#' + newHexRColor + newHexGColor + newHexBColor
}
